import React from "react";
import { FaRegArrowAltCircleRight } from "react-icons/fa";

const MiniHeader = () => {
  return (
    <div className="hidden md:block">
      <div className=" flex justify-center gap-2 items-center bg-white h-[40px] py-2">
        <p className="text-primary text-[0.8rem] sm:text-[1rem] md:text-[1rem] lg:text-[1rem] 2xl:text-[1rem] font-semibold">
          Destacados del día ¡no los dejes pasar!
        </p>
        <div className="text-primary text-[1rem]">
          <FaRegArrowAltCircleRight />
        </div>
      </div>
    </div>
  );
};

export default MiniHeader;
