import React from "react";
import IcHome from "../../../assets/ecommerce/icon/bottom-icon/IcHome";
import IcCategory from "../../../assets/ecommerce/icon/bottom-icon/IcCategory";
import IcPromotion from "../../../assets/ecommerce/icon/bottom-icon/IcPromotion";
import IcBrand from "../../../assets/ecommerce/icon/bottom-icon/IcBrand";
import IcSettings from "../../../assets/ecommerce/icon/bottom-icon/IcSettings";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

const BottomMenu = () => {
  const location = useLocation();
  const isHome = location.pathname === "/";
  const isCategory = location.pathname === "/category";
  const isPromotion = location.pathname === "/promotion";
  const isBrand = location.pathname === "/brand";
  return (
    <div className="grid grid-cols-5 grid-flow-col items-center place-items-center w-full h-full text-center">
      <Link to={"/"}>
        <div
          className={`flex justify-center items-center flex-col gap-y-1
        ${
          isHome
            ? "bg-slate-300/20 px-2 py-1 rounded-lg text-[#B9BCBE] font-semibold"
            : "text-[#e0e3e4]"
        }
        `}
        >
          <IcHome />
          <p className="text-xs font-medium">Home</p>
        </div>
      </Link>
      <Link to={"/category"}>
        <div
          className={`flex justify-center items-center flex-col gap-y-1    ${
            isCategory
              ? "bg-slate-300/20 px-2 py-1 rounded-lg text-[#B9BCBE] font-semibold"
              : "text-[#e0e3e4]"
          }`}
        >
          <IcCategory />
          <p className="text-xs font-medium">Categorías</p>
        </div>
      </Link>
      <Link to={"/promotion"}>
        <div
          className={`flex justify-center items-center flex-col gap-y-1 
           ${
             isPromotion
               ? "bg-slate-300/20 px-2 py-1 rounded-lg text-[#B9BCBE] font-semibold"
               : "text-[#e0e3e4]"
           }
        `}
        >
          <IcPromotion />
          <p className="text-xs font-medium">Promos</p>
        </div>
      </Link>
      <Link to={"/brand"}>
        <div
          className={`flex justify-center items-center flex-col gap-y-1

           ${
             isBrand
               ? "bg-slate-300/20 px-2 py-1 rounded-lg text-[#B9BCBE] font-semibold"
               : "text-[#e0e3e4]"
           }
        `}
        >
          <IcBrand />
          <p className="text-xs font-medium">Marcas</p>
        </div>
      </Link>
      <Link to={"/dashboard"}>
        <div className="flex justify-center items-center flex-col gap-y-1">
          <IcSettings /> <p className="text-xs font-medium">Perfil</p>
        </div>
      </Link>
    </div>
  );
};

export default BottomMenu;
