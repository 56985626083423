import React from "react";
import { useLocation } from "react-router";

const IcHome = () => {
  const location = useLocation();
  const isActive = location.pathname === "/";
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1522 5.59123C18.8642 6.16388 19.2822 7.02539 19.2913 7.93905L19.2826 15.913C19.2826 18.0789 17.5268 19.8347 15.3609 19.8347H13.6217C12.5734 19.8347 11.7222 18.9874 11.7174 17.9391V14.3391C11.7174 14.0605 11.4916 13.8347 11.213 13.8347H8.60435C8.46907 13.8347 8.33945 13.889 8.24461 13.9855C8.14977 14.082 8.09767 14.2125 8.1 14.3478V15.7217C8.1 16.1539 7.74961 16.5043 7.31739 16.5043C6.88517 16.5043 6.53478 16.1539 6.53478 15.7217V14.3478C6.53478 13.7966 6.75374 13.2679 7.14349 12.8782C7.53324 12.4884 8.06185 12.2695 8.61304 12.2695H11.2217C12.3661 12.2743 13.2913 13.2033 13.2913 14.3478V17.9391C13.2913 18.1215 13.4392 18.2695 13.6217 18.2695H15.4043C16.701 18.2695 17.7522 17.2183 17.7522 15.9217V7.95645C17.7458 7.51519 17.5415 7.10015 17.1957 6.82601L11.187 2.03471C10.4293 1.41091 9.33595 1.41091 8.57826 2.03471L6.64783 3.47819C6.29004 3.73512 5.79172 3.65336 5.53478 3.29558C5.27785 2.93779 5.35961 2.43947 5.71739 2.18253L7.62174 0.799925C8.94214 -0.261433 10.8231 -0.261433 12.1435 0.799925L18.1522 5.59123ZM4.42174 18.2608H8.42174V18.2782C8.85396 18.2782 9.20435 18.6286 9.20435 19.0608C9.20435 19.493 8.85396 19.8434 8.42174 19.8434H4.42174C2.25781 19.8386 0.504782 18.0856 0.5 15.9217V7.95645C0.513835 7.03602 0.945876 6.17194 1.67391 5.60862L2.39565 5.06949C2.73552 4.90927 3.14129 5.0132 3.36229 5.31707C3.58329 5.62095 3.55714 6.039 3.3 6.31297L2.59565 6.83471C2.26191 7.11198 2.06776 7.52256 2.06522 7.95645V15.913C2.07474 17.2091 3.12562 18.2561 4.42174 18.2608Z"
        fill={isActive ? "#423F97" : "#e0e3e4"}
      />
    </svg>
  );
};

export default IcHome;
