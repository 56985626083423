import { useEffect } from "react";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

const ProgressFallback = () => {
  useEffect(() => {
    NProgress.configure({ minimum: 0.1, speed: 800, trickleSpeed: 200 });
    NProgress.start();

    const timeoutId = setTimeout(() => {
      NProgress.done();
    }, 2000);

    return () => {
      clearTimeout(timeoutId);
      NProgress.done();
    };
  }, []);

  return null;
};

export default ProgressFallback;
