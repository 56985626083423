import { configureStore } from "@reduxjs/toolkit";
import syncProductReducer from "../slices/syncProductSlice";
import productReducer from "../slices/productSlice";

export const store = configureStore({
  reducer: {
    syncProducts: syncProductReducer,
    // Frontend
    products: productReducer,
  },
});
