import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getAllBrands,
  getAllCategories,
  getAllProducts,
} from "../../api/ecommerce/productApi";
import { getCart } from "../../api/ecommerce/cart";

const ContextApi = ({ children }) => {
  const [user_id, setuser_id] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const storedUserId = localStorage.getItem("user");
    if (storedUserId) {
      const parsedUserId = JSON.parse(storedUserId);
      setuser_id(parsedUserId.id);
    }
  }, []);

  useEffect(() => {
    handleFetchProduct();
  }, []);

  useEffect(() => {
    if (user_id) {
      getCart(dispatch, user_id);
    }
  }, [user_id]);

  const handleFetchProduct = () => {
    getAllProducts(dispatch);
    getAllCategories(dispatch);
    getAllBrands(dispatch);
  };

  return <div>{children}</div>;
};

export default ContextApi;
