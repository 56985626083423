import { RouterProvider } from "react-router-dom";
import "./App.css";
import EcomRouters from "./router/Index";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "nprogress/nprogress.css";

function App() {
  return <RouterProvider router={EcomRouters} />;
}

export default App;
