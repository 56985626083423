import React, { Suspense } from "react";
import Ecomlayout from "../../layout/ecommerce/Ecomlayout";
import ProgressFallback from "../../component/progress-bar/ProgressBar";
import PrivateRoute from "./PrivateRoute";

// Lazy loaded components
const Login = React.lazy(() => import("../../view/ecommerce/auth/login/Index"));
const Register = React.lazy(() =>
  import("../../view/ecommerce/auth/register/Index")
);
const Brand = React.lazy(() => import("../../view/ecommerce/brand/Brand"));
const Category = React.lazy(() =>
  import("../../view/ecommerce/category/Category")
);
const Dashboard = React.lazy(() =>
  import("../../view/ecommerce/dashboard/Index")
);
const Home = React.lazy(() => import("../../view/ecommerce/home/Index"));
const Product = React.lazy(() =>
  import("../../view/ecommerce/product/Product")
);
const Promotions = React.lazy(() =>
  import("../../view/ecommerce/promotion/Index")
);
const Workstation = React.lazy(() =>
  import("../../view/ecommerce/workstation/Workstation")
);
const CategoryProducts = React.lazy(() =>
  import("./../../view/ecommerce/category-products/CategoryProducts")
);

const BrandProducts = React.lazy(() =>
  import("./../../view/ecommerce/brand-products/BrandProducts")
);
const EmailRegister = React.lazy(() =>
  import("../../view/ecommerce/auth/register/EmailRegister")
);

const EmailLogin = React.lazy(() =>
  import("../../view/ecommerce/auth/login/EmailLogin")
);

const Checkout = React.lazy(() =>
  import("../../view/ecommerce/checkout/Checkout")
);
const Payment = React.lazy(() =>
  import("../../view/ecommerce/payment/Payment")
);

const Address = React.lazy(() =>
  import("../../view/ecommerce/address/Address")
);
const StripeSucess = React.lazy(() =>
  import("../../view/ecommerce/success/stripe/StripeSucess")
);

const StripeCancel = React.lazy(() =>
  import("../../view/ecommerce/success/stripe/StripeCancel")
);

const PaypalSucess = React.lazy(() =>
  import("../../view/ecommerce/success/paypal/PaypalSucess")
);

const EcomRouter = {
  path: "/",
  element: <Ecomlayout />,
  children: [
    {
      path: "/",
      element: (
        <Suspense fallback={<ProgressFallback />}>
          <Home />
        </Suspense>
      ),
    },
    {
      path: "/promotion",
      element: (
        <Suspense fallback={<ProgressFallback />}>
          <Promotions />
        </Suspense>
      ),
    },
    {
      path: "/category",
      element: (
        <Suspense fallback={<ProgressFallback />}>
          <Category />
        </Suspense>
      ),
    },
    {
      path: "/brand",
      element: (
        <Suspense fallback={<ProgressFallback />}>
          <Brand />
        </Suspense>
      ),
    },
    {
      path: "/workstation",
      element: (
        <Suspense fallback={<ProgressFallback />}>
          <Workstation />
        </Suspense>
      ),
    },
    {
      path: "/product/:sku/:product_name",
      element: (
        <Suspense fallback={<ProgressFallback />}>
          <Product />
        </Suspense>
      ),
    },
    {
      path: "/login",
      element: (
        <Suspense fallback={<ProgressFallback />}>
          <Login />
        </Suspense>
      ),
    },
    {
      path: "/email-login",
      element: (
        <Suspense fallback={<ProgressFallback />}>
          <EmailLogin />
        </Suspense>
      ),
    },
    {
      path: "/register",
      element: (
        <Suspense fallback={<ProgressFallback />}>
          <Register />
        </Suspense>
      ),
    },
    {
      path: "/email-register",
      element: (
        <Suspense fallback={<ProgressFallback />}>
          <EmailRegister />
        </Suspense>
      ),
    },
    {
      path: "/dashboard",
      element: (
        <PrivateRoute>
          <Suspense fallback={<ProgressFallback />}>
            <Dashboard />
          </Suspense>
        </PrivateRoute>
      ),
    },
    {
      path: "/category/:category_name",
      element: (
        <Suspense fallback={<ProgressFallback />}>
          <CategoryProducts />
        </Suspense>
      ),
    },
    {
      path: "/brand/:brand_name",
      element: (
        <Suspense fallback={<ProgressFallback />}>
          <BrandProducts />
        </Suspense>
      ),
    },
    {
      path: "/checkout",
      element: (
        <PrivateRoute>
          <Suspense fallback={<ProgressFallback />}>
            <Checkout />
          </Suspense>
        </PrivateRoute>
      ),
    },
    {
      path: "/payments",
      element: (
        <PrivateRoute>
          {" "}
          <Suspense fallback={<ProgressFallback />}>
            <Payment />
          </Suspense>
        </PrivateRoute>
      ),
    },
    {
      path: "/address",
      element: (
        <PrivateRoute>
          <Suspense fallback={<ProgressFallback />}>
            <Address />
          </Suspense>
        </PrivateRoute>
      ),
    },
    {
      path: "/stripe/success",
      element: (
        <PrivateRoute>
          <Suspense fallback={<ProgressFallback />}>
            <StripeSucess />
          </Suspense>
        </PrivateRoute>
      ),
    },
    {
      path: "/stripe/cancel",
      element: (
        <Suspense fallback={<ProgressFallback />}>
          <StripeCancel />
        </Suspense>
      ),
    },
    {
      path: "/paypal/success",
      element: (
        <PrivateRoute>
          <Suspense fallback={<ProgressFallback />}>
            <PaypalSucess />
          </Suspense>
        </PrivateRoute>
      ),
    },
  ],
};

export default EcomRouter;
