import { Bounce, toast } from "react-toastify";
import AxiosInstance from "../../axiosInstance";
import { setCart } from "../../redux/slices/productSlice";

export const addToCart = (form) => {
  AxiosInstance.post("/cart/add-to-cart", form)
    .then((response) => {
      if (response.status === 200) {
        toast.success("Product has been added to cart.", {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
    })
    .catch((error) => {
      toast.warning("Product already in cart.", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    });
};

export const getCart = async (dispatch, user_id) => {
  try {
    const response = await AxiosInstance.get(`/cart/cart/${user_id}`);
    if (response.status === 404) {
      dispatch(setCart([]));
    } else {
      dispatch(setCart(response.data));
    }
  } catch (error) {
    dispatch(setCart([]));
  }
};

export const deleteCart = async (userId, ref_id, dispatch) => {
  const user_id = userId;
  try {
    const response = await AxiosInstance.delete(
      `/cart/remove-from-cart/${userId}/${ref_id}`
    );
    if (response.status === 200) {
      toast.success("Cart has been deleted.", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });

      getCart(dispatch, user_id);
    }
  } catch (error) {
    console.log(error);
  }
};
