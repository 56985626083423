import { Bounce, toast } from "react-toastify";
import AxiosInstance from "../../axiosInstance";
import {
  setBrand,
  setCategory,
  setProducts,
  setPromotion,
} from "../../redux/slices/productSlice";

export const getAllProducts = async (dispatch) => {
  try {
    const response = await AxiosInstance.get("/product/products");
    dispatch(setProducts(response.data));
  } catch (error) {
    console.log(error);
  }
};
export const getAllCategories = async (dispatch) => {
  try {
    const response = await AxiosInstance.get("/frontend/categories");
    dispatch(setCategory(response.data.categories));
  } catch (error) {
    console.log(error);
  }
};
export const getCategoryProduct = async (category_name, setData) => {
  try {
    const response = await AxiosInstance.get(
      `/frontend/category-name/${category_name}`
    );
    setTimeout(() => {
      setData(response.data);
    }, 800);
  } catch (error) {
    console.log(error);
  }
};

export const getSingleProduct = async (sku, product_name, setData) => {
  try {
    const response = await AxiosInstance.get(
      `/product/products/${sku}/${product_name}`
    );
    setData(response.data);
  } catch (error) {
    console.log(error);
  }
};

export const getBrandProduct = async (brand_name, setData) => {
  try {
    const response = await AxiosInstance.get(
      `/frontend/brand-name/${brand_name}`
    );
    setTimeout(() => {
      setData(response.data);
    }, 800);
  } catch (error) {
    console.log(error);
  }
};

export const getAllBrands = async (dispatch) => {
  try {
    const response = await AxiosInstance.get("/frontend/brands");
    dispatch(setBrand(response.data.brands));
  } catch (error) {
    console.log(error);
  }
};

export const promotionalProducts = async (dispatch) => {
  try {
    const response = await AxiosInstance.get("/promotion/promotion-products");
    setTimeout(() => {
      dispatch(setPromotion(response.data));
    }, 800);
  } catch (error) {
    console.log(error);
  }
};

export const addReview = async (FormForReview, setShowReviewAdd) => {
  try {
    const response = await AxiosInstance.post(
      "/product/add-review",
      FormForReview
    );
    if (response.status === 201) {
      toast.success("Review has been added.", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      setShowReviewAdd(false);
    }
  } catch (error) {
    console.log(error);
  }
};

export const getReviews = async (setReviewData, sku) => {
  try {
    const response = await AxiosInstance.get(`/product/reviews/${sku}`);
    setReviewData(response.data.reviews);
  } catch (error) {
    console.log(error);
  }
};
