import { Bounce, toast } from "react-toastify";
import AxiosInstance from "../../axiosInstance";

export const addCategory = async (data, setData) => {
  const formData = new FormData();

  // Append form fields
  formData.append("category_name", data.category_name);

  // Append file
  if (data.category_image) {
    formData.append("category_image", data.category_image);
  }

  // Append the products array as a JSON string
  formData.append("products", JSON.stringify(data.products));

  try {
    const response = await AxiosInstance.post(
      "/frontend/add-category-page",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response.status === 201) {
      toast.success("Category has been added.", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      setData({
        category_name: "",
        category_image: null,
        products: [],
      });
    }
  } catch (error) {
    console.error("Error adding category:", error);
    throw error;
  }
};

export const getAllCategories = async (setData) => {
  try {
    const response = await AxiosInstance.get("/frontend/categories");
    setData(response.data.categories);
  } catch (error) {
    console.log(error);
  }
};

export const deleteCategory = async (id, setdata) => {
  try {
    const response = await AxiosInstance.delete(`/frontend/categories/${id}`);
    if (response.status === 200) {
      toast.success("Category has been deleted.", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      getAllCategories(setdata);
    }
  } catch (error) {
    console.log(error);
  }
};

export const getCategoryById = async (category_id, setData) => {
  try {
    const response = await AxiosInstance.get(
      `/frontend/category/${category_id}`
    );
    const data = response.data;
    setData({
      category_name: data.category_name,
      category_image: data.category_image,
      products: data.products,
    });
  } catch (error) {
    console.log(error);
  }
};

export const updateCategory = async (data, category_id) => {
  const formData = new FormData();

  // Append form fields
  formData.append("category_name", data.category_name);

  // Append file
  if (data.category_image) {
    formData.append("category_image", data.category_image);
  }

  // Append the products array as a JSON string
  formData.append("products", JSON.stringify(data.products));

  try {
    const response = await AxiosInstance.put(
      `/frontend/update-category-page/${category_id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response.status === 200) {
      toast.success("Category has been edited.", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  } catch (error) {
    console.error("Error adding category:", error);
    throw error;
  }
};
