import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  syncProducts: [],
  syncProductDetails: [],
  allRefIds: [],
};

const syncProductSlice = createSlice({
  name: "syncProducts",
  initialState,
  reducers: {
    setSyncProducts: (state, action) => {
      state.syncProducts = action.payload;
    },
    setSyncProductDetails: (state, action) => {
      state.syncProductDetails.push(action.payload);
    },
    setAllRefIds: (state, action) => {
      state.allRefIds = action.payload;
    },
  },
});

export const { setSyncProducts, setSyncProductDetails, setAllRefIds } =
  syncProductSlice.actions;
export default syncProductSlice.reducer;
