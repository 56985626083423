import AxiosInstance from "./../../axiosInstance";
export const sendMessage = (ChatData, handleViewMessage) => {
  AxiosInstance.post("/chat/send-message", ChatData)
    .then((response) => {
      if (response.status === 201) {
        handleViewMessage();
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getMessages = (setMessages) => {
  AxiosInstance.get("/chat/messages/3")
    .then((response) => {
      setMessages(response.data.messages);
    })
    .catch((error) => {
      console.log(error);
    });
};
