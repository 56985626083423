import React from "react";
import { useLocation } from "react-router";

const IcBrand = () => {
  const location = useLocation();
  const isActive = location.pathname === "/brand";
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5206 0.802704C17.1348 1.30469 18.4771 2.45775 19.2354 3.99382C20.122 5.85558 20.2427 8.00082 19.5707 9.95358C18.9521 11.7513 17.938 13.3809 16.6059 14.7178C15.7325 15.6155 14.7914 16.442 13.7912 17.1898L13.7383 17.2347C13.406 17.4584 12.9591 17.3706 12.7324 17.037C12.6171 16.8759 12.5708 16.674 12.6041 16.4776C12.6373 16.2811 12.7473 16.1067 12.9088 15.9942C13.8474 15.2871 14.7323 14.5088 15.5559 13.6661C16.7421 12.4987 17.648 11.0683 18.203 9.48615C18.7368 7.90956 18.6288 6.18086 17.903 4.68598C17.3189 3.51 16.2899 2.62715 15.053 2.24096C13.4753 1.73838 11.7565 2.04305 10.4382 3.05896C10.1762 3.25674 9.81797 3.25674 9.55588 3.05896C8.23818 2.04164 6.5187 1.73684 4.94115 2.24096C3.69596 2.61862 2.65663 3.4988 2.06466 4.67699C1.35526 6.17077 1.25377 7.88955 1.78231 9.45918C2.33967 11.0376 3.24529 12.4645 4.42938 13.6301C6.179 15.3392 8.10833 16.8465 10.1824 18.1246C10.4461 18.3111 10.5621 18.6493 10.4697 18.9625C10.3773 19.2757 10.0974 19.4927 9.77647 19.5C9.63179 19.5008 9.49026 19.457 9.37059 19.3741C7.21007 18.0429 5.20077 16.4723 3.37938 14.6908C2.03993 13.3583 1.02208 11.7276 0.405828 9.92661C-0.242989 7.97655 -0.109803 5.84437 0.776419 3.99382C1.5347 2.45775 2.87695 1.30469 4.49115 0.802704C6.34706 0.218706 8.36066 0.487845 10.0059 1.53981C11.6511 0.487845 13.6647 0.218706 15.5206 0.802704ZM14.7971 7.68833C14.7602 6.85762 14.2172 6.13914 13.4383 5.89052C13.1487 5.70632 13.02 5.34441 13.1267 5.01402C13.2334 4.68363 13.5479 4.47024 13.8883 4.49721C15.2039 4.92033 16.1404 6.10845 16.2618 7.50855C16.2867 7.70431 16.2335 7.90206 16.1141 8.0575C15.9946 8.21295 15.819 8.31311 15.6265 8.33555C15.2224 8.38874 14.8519 8.09965 14.7971 7.68833Z"
        fill={isActive ? "#423F97" : "#e0e3e4"}
      />
    </svg>
  );
};

export default IcBrand;
