import React from "react";
import { useLocation } from "react-router";

const IcPromotion = () => {
  const location = useLocation();
  const isActive = location.pathname === "/promotion";
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4895 4.66448C19.0925 4.19609 18.5155 3.92669 17.9085 3.92669H7.16377C6.74978 3.92669 6.41378 4.26956 6.41378 4.69203C6.41378 5.11451 6.74978 5.45738 7.16377 5.45738H17.9085C18.1435 5.45738 18.2895 5.588 18.3555 5.66556C18.4215 5.74413 18.5275 5.91047 18.4935 6.14721L17.5445 12.8404C17.4695 13.365 17.0215 13.7609 16.5015 13.7609H5.59081C5.03782 13.7609 4.58683 13.3374 4.54083 12.7761L3.57786 1.06939C3.54986 0.722434 3.29486 0.437725 2.95887 0.378538L0.877924 0.0101507C0.464935 -0.0571999 0.0809442 0.217305 0.0109459 0.634674C-0.0590523 1.05102 0.213941 1.44696 0.621931 1.51942L2.13189 1.78576L3.04587 12.9037C3.15787 14.265 4.25184 15.2916 5.59081 15.2916H16.5015C17.7625 15.2916 18.8485 14.3323 19.0285 13.0598L19.9784 6.36661C20.0654 5.7523 19.8864 5.13287 19.4895 4.66448ZM3.90795 18.455C3.90795 17.6029 4.58793 16.909 5.42291 16.909C6.25789 16.909 6.93687 17.6029 6.93687 18.455C6.93687 19.3071 6.25789 20 5.42291 20C4.58793 20 3.90795 19.3071 3.90795 18.455ZM15.1607 18.455C15.1607 17.6029 15.8396 16.909 16.6746 16.909C17.5096 16.909 18.1896 17.6029 18.1896 18.455C18.1896 19.3071 17.5096 20 16.6746 20C15.8396 20 15.1607 19.3071 15.1607 18.455ZM15.6488 8.46428C15.6488 8.88675 15.3128 9.22963 14.8988 9.22963H12.1258C11.7109 9.22963 11.3759 8.88675 11.3759 8.46428C11.3759 8.04181 11.7109 7.69893 12.1258 7.69893H14.8988C15.3128 7.69893 15.6488 8.04181 15.6488 8.46428Z"
        fill={isActive ? "#423F97" : "#e0e3e4"}
      />
    </svg>
  );
};

export default IcPromotion;
