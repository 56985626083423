import React, { useState } from "react";
import Container from "../../../utils/ecommerce/Container";
import Logo from "../../../assets/ecommerce/logo/logoWhite.png";
import Facebook from "../../../assets/ecommerce/social/facebook.png";
import linkedIn from "../../../assets/ecommerce/social/linkedin.png";
import Instagram from "../../../assets/ecommerce/social/instagram.png";
import AxiosInstance from "../../../axiosInstance";
import { Bounce, toast } from "react-toastify";

const Footer = () => {
  const [email, setEmail] = useState("");
  const handleSubscribe = () => {
    AxiosInstance.post("/subscriber/add-subscribe", {
      email_address: email,
    })
      .then((res) => {
        if (res.status === 201) {
          toast.success("You have subscribed.", {
            position: "top-right",
            autoClose: 1200,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });

          setEmail("");
        }
      })
      .catch((err) => {
        if (err.status === 409) {
          toast.warn("You have already subscribed.", {
            position: "top-right",
            autoClose: 1200,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
          setEmail("");
        }
      });
  };
  return (
    <div className="bg-gradient-to-t from-[#423F97] to-[#343191] py-10 mt-10 md:-mt-3">
      <Container>
        <div className="flex flex-col gap-10 md:flex-row lg:flex-row xl:flex-row 2xl:flex-row">
          <div>
            <img className="w-[171px]" src={Logo} alt="Logo" />
            <div className="w-[250px] h-auto break-words mt-10 text-white">
              <p>Loremipsumloremipsumloremipsumloremipsumloremipsumlorem.</p>
            </div>
            <div className="flex justify-start items-center mt-10 gap-5 hidden md:flex ">
              <div className="cursor-pointer">
                <img src={Facebook} alt="netlab-facebook" />
              </div>
              <div className="cursor-pointer">
                <img src={linkedIn} alt="net-lab-linkedin" />
              </div>
              <div className="cursor-pointer">
                <img src={Instagram} alt="netlab-instagram" />
              </div>
            </div>
            <div className="w-[250px] h-auto hidden md:block lg:block xl:block 2xl:block break-words mt-10 text-white">
              <p>© Netlab Technology S.A.S. de C.V.</p>
            </div>
          </div>
          <div className="mx-auto mt-10 md:mt-0">
            <div className="flex items-start gap-x-32">
              <div>
                <p className="text-white font-bold mb-8">Tienda</p>
                <p className="text-white  mb-8">Por marcas</p>
                <p className="text-white  mb-8">Para mayoristas</p>
              </div>
              <div>
                <p className="text-white font-bold mb-8">Configurador de PC</p>
                <p className="text-white  mb-8">Arma tu pc ¡ahora!</p>
              </div>
            </div>
            <div className="mt-10">
              <div>
                <p className="text-white text-[1.2rem] font-bold">
                  Suscríbete a nuestro boletín
                </p>
                <p className="text-white">
                  ¡Entérate de nuestro lanzamiento y ofertas especiales antes
                  que nadie!
                </p>
              </div>
              <div>
                <div className="flex items-center justify-start mt-5">
                  <div>
                    <input
                      className="py-2 w-[320px] bg-transparent border-[2px] border-secondary rounded-full px-2 focus:outline-none text-white"
                      type="text"
                      placeholder="Tu email..."
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div>
                    <button
                      onClick={handleSubscribe}
                      className="px-2 md:px-10 lg:px-5 xl:px-5 2xl:px-5 py-2 relative right-10 md:right-10 bg-secondary text-xs md:text-xs lg:text-lg xl:text-lg 2xl:text-lg rounded-full text-white"
                    >
                      Recibir ofertas
                    </button>
                  </div>
                </div>
                <div className="flex justify-start items-center mt-10 gap-5 block md:hidden">
                  <div className="cursor-pointer">
                    <img src={Facebook} alt="netlab-facebook" />
                  </div>
                  <div className="cursor-pointer">
                    <img src={linkedIn} alt="net-lab-linkedin" />
                  </div>
                  <div className="cursor-pointer">
                    <img src={Instagram} alt="netlab-instagram" />
                  </div>
                </div>
                <div className="h-auto block md:hidden lg:hidden xl:hidden 2xl:hidden break-words mt-10 text-white">
                  <p>© Netlab Technology S.A.S. de C.V.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
