import React, { Suspense } from "react";
import ProgressFallback from "../../component/progress-bar/ProgressBar";

const AdminLogin = React.lazy(() =>
  import("../../component/auth/login/AdminLogin")
);
const AuthLayout = React.lazy(() => import("../../layout/auth/AuthLayout"));

const authRouter = {
  path: "/auth",
  element: <AuthLayout />,
  children: [
    {
      path: "/auth/login",
      element: (
        <Suspense fallback={<ProgressFallback />}>
          <AdminLogin />
        </Suspense>
      ),
    },
  ],
};

export default authRouter;
