import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  addCategory,
  getCategoryById,
  updateCategory,
} from "../../../../api/admin/category";
import { useParams } from "react-router";

const EditCategory = () => {
  const { category_id } = useParams();
  const productData = useSelector((state) => state.products.products);
  const [data, setData] = useState({
    category_name: "",
    category_image: "",
    products: [],
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleSelectProduct = (product) => {
    const existingProduct = data.products.find(
      (item) => item.ref_id === product.ref_id
    );
    if (!existingProduct) {
      setData((prevData) => ({
        ...prevData,
        products: [...prevData.products, { ref_id: product.ref_id }], // Store as an object
      }));
    }
    setIsOpen(false);
  };

  const handleDeleteProduct = (ref_id) => {
    setData((prevData) => ({
      ...prevData,
      products: prevData.products.filter((item) => item.ref_id !== ref_id), // Filter based on object structure
    }));
  };

  const filteredProducts = productData.filter((product) =>
    product.product_name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  useEffect(() => {
    getCategoryById(category_id, setData);
  }, [category_id]);

  const handleUpdate = () => {
    updateCategory(data, category_id);
  };

  return (
    <div className="flex justify-center">
      <div className="w-3/4 h-full border rounded-lg px-10 py-10">
        <div>
          <p className="text-2xl font-semibold">Edit Category</p>
        </div>
        <div className="mt-10">
          <form action="">
            <div>
              <label htmlFor="">Category Name</label>
              <input
                type="text"
                placeholder="Enter Category Name"
                className="w-full border p-2 rounded-lg focus:outline-none"
                value={data.category_name}
                onChange={(e) =>
                  setData({ ...data, category_name: e.target.value })
                }
              />
            </div>
            <div className="mt-10">
              <label htmlFor="">Category Image</label>
              <input
                type="file"
                className="w-full border p-2 rounded-lg focus:outline-none"
                onChange={(e) =>
                  setData({ ...data, category_image: e.target.files[0] })
                }
              />
            </div>
            <div className="mt-10">
              <label htmlFor="">Select Products</label>
              <div className="relative">
                <div
                  className="border p-2 rounded-lg cursor-pointer"
                  onClick={() => setIsOpen((prev) => !prev)}
                >
                  {data.products.length === 0
                    ? "Select Products"
                    : data.products
                        .map((item) => {
                          const product = productData.find(
                            (prod) => prod.ref_id === item.ref_id
                          );
                          return product ? product.product_name : "";
                        })
                        .join(", ")}
                </div>
                {isOpen && (
                  <div className="absolute z-10 mt-1 w-full border rounded-lg bg-white shadow-lg">
                    <input
                      type="text"
                      placeholder="Search products..."
                      className="w-full border-b p-2 rounded-t-lg focus:outline-none"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <div className="max-h-40 overflow-y-auto">
                      {filteredProducts.length === 0 ? (
                        <div className="p-2">No products found</div>
                      ) : (
                        filteredProducts.slice(0, 10).map((item) => (
                          <div
                            key={item.ref_id}
                            className="p-2 hover:bg-gray-200 cursor-pointer"
                            onClick={() => handleSelectProduct(item)}
                          >
                            <div className="flex items-center gap-3">
                              <div className="w-10 h-10">
                                <img
                                  src={
                                    item.images && item.images.length > 0
                                      ? item.images[0].image_url
                                      : ""
                                  }
                                  alt=""
                                />
                              </div>
                              <div>{item.product_name}</div>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="mt-2">
                {data.products.map((item) => {
                  const product = productData.find(
                    (prod) => prod.ref_id === item.ref_id
                  );
                  return (
                    product && (
                      <span
                        key={item.ref_id}
                        className="inline-flex items-center bg-blue-200 text-blue-800 mb-2 text-sm font-medium mr-2 px-2.5 py-0.5 rounded"
                      >
                        {product.product_name}
                        <button
                          onClick={() => handleDeleteProduct(item.ref_id)}
                          className="ml-1 text-blue-600 hover:text-blue-800"
                        >
                          &times;
                        </button>
                      </span>
                    )
                  );
                })}
              </div>
            </div>
          </form>
          <div className="mt-10 flex justify-end items-center gap-3">
            <button
              onClick={handleUpdate}
              className="p-2 text-sm rounded-lg bg-secondary text-white font-semibold"
              type="submit"
            >
              Update Category
            </button>
            <button
              className="p-2 text-sm rounded-lg bg-secondary text-white font-semibold"
              type="button"
              onClick={() => setData({ ...data, products: [] })}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCategory;
