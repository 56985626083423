import React, { useEffect, useState } from "react";
import chatLogo from "../../../assets/ecommerce/icon/chat/chat.png";
import { IoCloseCircle } from "react-icons/io5";
import ChatBody from "./ChatBody";
import ChatAction from "./ChatAction";
import io from "socket.io-client";
import { getMessages, sendMessage } from "../../../api/ecommerce/chat";
const socket = io("https://api.netlab.mx");
const Chat = () => {
  const [ChatData, setChatData] = useState({
    sender_id: "3",
    message: "",
  });
  const [MessageData, setMessageData] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    setChatData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };
  const [UserID, setUserID] = useState("");

  useEffect(() => {
    const user = localStorage.getItem("user");
    const parsedUser = JSON.parse(user);
    if (parsedUser) {
      setUserID(parsedUser.id);
    }
  }, []);
  const handleViewMessage = () => {
    getMessages(setMessageData);
  };
  useEffect(() => {
    handleViewMessage();
  }, []);
  // socket.on("new_message", (data) => {
  //     setMessageData((prevState) => [...prevState, data]);
  //   });
  const handleSendMessage = () => {
    sendMessage(ChatData, handleViewMessage);
  };
  return (
    <div className="duration-300 fixed bottom-5 right-5 z-50 ">
      {open === false && (
        <div
          onClick={handleOpen}
          className="w-[80px] h-[80px] cursor-pointer duration-300"
        >
          <img src={chatLogo} alt="" />
        </div>
      )}
      {open && (
        <div className="duration-300 w-[400px] h-[400px]  bg-white rounded-lg shadow-2xl transition-all overflow-hidden">
          <span
            onClick={handleOpen}
            className="absolute -top-2 -right-2 text-red-500 bg-white text-2xl rounded-full drop-shadow-lg cursor-pointer"
          >
            <IoCloseCircle />
          </span>
          <div className="py-2 border-b text-center bg-white rounded-t-lg">
            <p className="font-bold text-primary">Netlab Live Support</p>
          </div>
          <div className="w-full  overflow-y-scroll scrollbar-hide px-5 h-[300px] py-5">
            <ChatBody UserID={UserID} MessageData={MessageData} />
          </div>
          <div className="h-[100px] bg-white border-t">
            <ChatAction
              ChatData={ChatData}
              handleChange={handleChange}
              setChatData={setChatData}
              handleSendMessage={handleSendMessage}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Chat;
