import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  products: [],
  category: [],
  brand: [],
  promotion: [],
  cart: [],
};

export const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload;
    },

    setCategory: (state, action) => {
      state.category = action.payload;
    },

    setBrand: (state, action) => {
      state.brand = action.payload;
    },

    setPromotion: (state, action) => {
      state.promotion = action.payload;
    },

    setCart: (state, action) => {
      state.cart = action.payload;
    },
  },
});

export const { setProducts, setCategory, setBrand, setPromotion, setCart } =
  productSlice.actions;

export default productSlice.reducer;
