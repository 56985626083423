import React, { Suspense } from "react";
import ProgressFallback from "../../component/progress-bar/ProgressBar";
import EditCategory from "../../view/admin/category/edit/EditCategory";
import AdminPrivateRoute from "./adminPrivateRoute";

const Category = React.lazy(() => import("../../view/admin/category/Category"));
const AddCategory = React.lazy(() =>
  import("../../view/admin/category/add/AddCategory")
);

const AdminLayout = React.lazy(() => import("../../layout/admin/AdminLayout"));
const Dashboard = React.lazy(() =>
  import("../../view/admin/Dashboard/Dashboard")
);

const SyncProducts = React.lazy(() =>
  import("../../view/admin/sync-product/SyncProducts")
);

const Brand = React.lazy(() => import("../../view/admin/brand/Brand"));
const AddBrand = React.lazy(() =>
  import("../../view/admin/brand/add/AddBrand")
);
const EditBrand = React.lazy(() =>
  import("../../view/admin/brand/edit/EditBrand")
);

const Promotion = React.lazy(() =>
  import("../../view/admin/promotion/Promotion")
);
const AddPromotion = React.lazy(() =>
  import("../../view/admin/promotion/add-promotion/AddPromotion")
);
const EditPromotion = React.lazy(() =>
  import("../../view/admin/promotion/EditPromotion/EditPromotion")
);

const ChatSysytem = React.lazy(() =>
  import("../../view/admin/chat-system/ChatSysytem")
);

const Order = React.lazy(() => import("../../view/admin/order/Order"));

const OrderDetails = React.lazy(() =>
  import("../../view/admin/order/view-order/OrderDetails")
);
const Product = React.lazy(() => import("../../view/admin/product/Product"));

const Review = React.lazy(() => import("../../view/admin/review/Review"));
const User = React.lazy(() => import("../../view/admin/user/User"));
const Subscribe = React.lazy(() =>
  import("../../view/admin/subscriber/Subscribe")
);
const SystemUsers = React.lazy(() =>
  import("../../view/admin/system-user/SystemUsers")
);

const AdminRoutes = {
  path: "/admin",
  element: (
    <AdminPrivateRoute>
      <AdminLayout />
    </AdminPrivateRoute>
  ),
  children: [
    {
      path: "/admin/dashboard",
      element: (
        <AdminPrivateRoute>
          <Suspense fallback={<ProgressFallback />}>
            <Dashboard />
          </Suspense>
        </AdminPrivateRoute>
      ),
    },
    {
      path: "/admin/sync-products",
      element: (
        <AdminPrivateRoute>
          <Suspense fallback={<ProgressFallback />}>
            <SyncProducts />
          </Suspense>
        </AdminPrivateRoute>
      ),
    },
    {
      path: "/admin/category",
      element: (
        <AdminPrivateRoute>
          <Suspense fallback={<ProgressFallback />}>
            <Category />
          </Suspense>
        </AdminPrivateRoute>
      ),
    },
    {
      path: "/admin/category/add",
      element: (
        <AdminPrivateRoute>
          <Suspense fallback={<ProgressFallback />}>
            <AddCategory />
          </Suspense>
        </AdminPrivateRoute>
      ),
    },
    {
      path: "/admin/category/edit/:category_id",
      element: (
        <AdminPrivateRoute>
          <Suspense fallback={<ProgressFallback />}>
            <EditCategory />
          </Suspense>
        </AdminPrivateRoute>
      ),
    },
    {
      path: "/admin/brand",
      element: (
        <AdminPrivateRoute>
          <Suspense fallback={<ProgressFallback />}>
            <Brand />
          </Suspense>
        </AdminPrivateRoute>
      ),
    },
    {
      path: "/admin/brand/add",
      element: (
        <AdminPrivateRoute>
          <Suspense fallback={<ProgressFallback />}>
            <AddBrand />
          </Suspense>
        </AdminPrivateRoute>
      ),
    },
    {
      path: "/admin/brand/edit/:brand_id",
      element: (
        <AdminPrivateRoute>
          <Suspense fallback={<ProgressFallback />}>
            <EditBrand />
          </Suspense>
        </AdminPrivateRoute>
      ),
    },
    {
      path: "/admin/Promotion",
      element: (
        <AdminPrivateRoute>
          <Suspense fallback={<ProgressFallback />}>
            <Promotion />
          </Suspense>
        </AdminPrivateRoute>
      ),
    },
    {
      path: "/admin/Promotion/add",
      element: (
        <AdminPrivateRoute>
          <Suspense fallback={<ProgressFallback />}>
            <AddPromotion />
          </Suspense>
        </AdminPrivateRoute>
      ),
    },
    {
      path: "/admin/Promotion/edit/:id",
      element: (
        <AdminPrivateRoute>
          <Suspense fallback={<ProgressFallback />}>
            <EditPromotion />
          </Suspense>
        </AdminPrivateRoute>
      ),
    },
    {
      path: "/admin/support",
      element: (
        <AdminPrivateRoute>
          <Suspense fallback={<ProgressFallback />}>
            <ChatSysytem />
          </Suspense>
        </AdminPrivateRoute>
      ),
    },
    {
      path: "/admin/orders",
      element: (
        <AdminPrivateRoute>
          <Suspense fallback={<ProgressFallback />}>
            <Order />
          </Suspense>
        </AdminPrivateRoute>
      ),
    },
    {
      path: "/admin/order/:order_id",
      element: (
        <AdminPrivateRoute>
          <Suspense fallback={<ProgressFallback />}>
            <OrderDetails />
          </Suspense>
        </AdminPrivateRoute>
      ),
    },
    {
      path: "/admin/review",
      element: (
        <AdminPrivateRoute>
          <Suspense fallback={<ProgressFallback />}>
            <Review />
          </Suspense>
        </AdminPrivateRoute>
      ),
    },
    {
      path: "/admin/products",
      element: (
        <AdminPrivateRoute>
          <Suspense fallback={<ProgressFallback />}>
            <Product />
          </Suspense>
        </AdminPrivateRoute>
      ),
    },
    {
      path: "/admin/users",
      element: (
        <AdminPrivateRoute>
          <Suspense fallback={<ProgressFallback />}>
            <User />
          </Suspense>
        </AdminPrivateRoute>
      ),
    },
    {
      path: "/admin/subscribe",
      element: (
        <AdminPrivateRoute>
          <Suspense fallback={<ProgressFallback />}>
            <Subscribe />
          </Suspense>
        </AdminPrivateRoute>
      ),
    },
    {
      path: "/admin/system-users",
      element: (
        <AdminPrivateRoute>
          <Suspense fallback={<ProgressFallback />}>
            <SystemUsers />
          </Suspense>
        </AdminPrivateRoute>
      ),
    },
  ],
};

export default AdminRoutes;
