import React from "react";
import { Outlet } from "react-router";
import Footer from "../../component/ecommerce/footer/Footer";
import MiniHeader from "../../component/ecommerce/header/miniHeader/MiniHeader";
import BottomMenu from "../../component/ecommerce/bottom-menu/BottomMenu";
import Chat from "../../component/ecommerce/chat/Chat";

const Ecomlayout = () => {
  return (
    <div className="w-screen h-screen flex flex-col overflow-x-hidden">
      <MiniHeader />
      <div className="flex-1">
        <Outlet />
      </div>
      <div className="fixed bottom-0 w-full h-[60px] bg-white  z-50 block md:hidden">
        <BottomMenu />
      </div>
      {/* <div>
        <Chat />
      </div> */}
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Ecomlayout;
